tinymce.PluginManager.add('recomendations', function(editor)
{
	var self = this;

	function getRecomendationOptions() {
		items = [];
		j = 0;
		jQuery.each(recomendationOptions, function (i) {
			items[j] = {
				text: recomendationOptions[i].text,
				disabled: true,
				classes: 'recomendation parent'
			};
			j++;

			jQuery.each(recomendationOptions[i].items, function (k) {
				items[j] = {
					text: recomendationOptions[i].items[k].text,
					value: recomendationOptions[i].items[k].value,
					classes: 'recomendation child',
					onclick: function(e) {
						console.clear();
						getRecomendationTemplate(recomendationOptions[i].text, recomendationOptions[i].items[k].value);
					}
				};
				j++;
			});
		});
		return items;
	};

	function getRecomendationTemplate(group, tid) {
		if (jQuery(myModel.exportHTML()).find('table.grp_' + group).length == 0) {
			basePath = window.location.href;
			if (basePath.lastIndexOf('#') > 0) basePath = basePath.substr(0, basePath.lastIndexOf('#'));
			if (basePath.lastIndexOf('?') > 0) basePath = basePath.substr(0, basePath.lastIndexOf('?'));
			if (basePath.lastIndexOf('/') > 0) basePath = basePath.substr(0, basePath.lastIndexOf('/'));
			url = basePath + '/backend/plugin';

			post = $.post(url, {action: 'recomendations', uid: userMetadata['uid'], bid: userMetadata['bid'], did: userMetadata['did'], tid: tid}, null, 'json');
			post.success(function (data) {
				if (data.length) {
					tableHead = '--><table ';
					data = '<div class="my_recomendations">' + data.substr(0, data.indexOf(tableHead)+tableHead.length) + 'class="mceNonEditable grp_' + group + ' "' + data.substr(data.indexOf(tableHead)+tableHead.length) + '</div>';
				}

				editor.insertContent(data);
			}).fail(function() {
				myModel.notifier.error(liveDataTrans['generic-server-error']);
			});
		}else {
			myModel.notifier.warning(liveDataTrans['recomendation-already-selected'].replace('__RECOMENDATION_GROUP__', group));
		}
	}

	items = getRecomendationOptions();

	if (items.length) {
		editor.addButton('recomendations', {
			text: 'Recomendations',
			type: 'menubutton',
			tooltip: 'Insert Recomendations',
			menu: items,
			context: 'insert'
		});
	}
});
