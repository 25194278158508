"use strict";

// Overrides native jQuery tabs to make tabs working also when using a base tag
// in order to avoid conflicts you have to add a data-local="true" attribute to your tab links.

var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);
var console = require('console-browserify');
var tabs = (typeof window !== "undefined" ? window['jQuery']['ui']['tabs'] : typeof global !== "undefined" ? global['jQuery']['ui']['tabs'] : null);

if (typeof tabs == 'undefined') throw "Cannot find jquery-ui tabs widget dependency!";

$.widget("ui.tabs", tabs, {
  _isLocal: function( anchor ) {
    if (anchor.getAttribute('data-local') == "true") return true;
    else return this._superApply( arguments );
  }
});