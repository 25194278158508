"use strict";

require("knockout-sortable");

require("./bindings/jqueryui-spinner.js");
require("./bindings/jqueryui-tabs.js");
require("./bindings/colorpicker.js");
require("./bindings/blocks.js");
require("./bindings/csstext.js");
require("./bindings/bind-iframe.js");
require("./bindings/extdroppable.js");
require("./bindings/preloader.js");
require("./bindings/fileupload.js");
require("./bindings/virtuals.js");
require("./bindings/wysiwygs.js");
require("./bindings/scrollfix.js");
require("./bindings/if-subs.js");
require("./bindings/extsortables.js");
require("./bindings/eventable.js");
require("./bindings/tooltips.js");
require("./bindings/extender-pagination.js");
require("./bindings/validated-value.js");
require("./bindings/scrollintoview.js");