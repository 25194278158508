"use strict";

var ko = (typeof window !== "undefined" ? window['ko'] : typeof global !== "undefined" ? global['ko'] : null);
var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);
var kojqui = (typeof window !== "undefined" ? window['kojqui'] : typeof global !== "undefined" ? global['kojqui'] : null);
var console = require('console-browserify');

var extendValueAccessor = function(valueAccessor, obj) {
  return function() {
    ko.utils.extend(obj, valueAccessor());
    return obj;
  };
};

var options = {
  show: {
    delay: 500
  },
  track: true,
  items: '[title][title!=""][title!=" "]'
};

// See https://bugs.jqueryui.com/ticket/15333#ticket for a known bug with Edge showing an empty "native tooltip" too.
ko.bindingHandlers.tooltips = {
  init: function(element, valueAccessor, allBindingsAccessor, data, context) {
    if (typeof $.fn.tooltip !== 'undefined' && typeof ko.bindingHandlers.tooltip !== 'undefined') {
      // position: { my: "left+15 top+15", at: "center+30 center+30" }
      // NOTE title with "" and " " is needed to avoid default tooltips in native file upload controls
      return ko.bindingHandlers.tooltip.init(element, extendValueAccessor(valueAccessor, options), allBindingsAccessor, data, context);
    }
  },
  update: function(element, valueAccessor, allBindingsAccessor, data, context) {
    if (typeof $.fn.tooltip !== 'undefined' && typeof ko.bindingHandlers.tooltip !== 'undefined') {
      return ko.bindingHandlers.tooltip.update(element, extendValueAccessor(valueAccessor, options), allBindingsAccessor, data, context);
    }
  },
};