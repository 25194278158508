"use strict";

var ko = (typeof window !== "undefined" ? window['ko'] : typeof global !== "undefined" ? global['ko'] : null);
var $ = (typeof window !== "undefined" ? window['jQuery'] : typeof global !== "undefined" ? global['jQuery'] : null);
var console = require('console-browserify');

/* utility for togetherjs */
ko.bindingHandlers.focusable = {
  'focus': function() {},
  'blur': function() {},
  'init': function(element) {
    ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
      $(element).off("focusin", ko.bindingHandlers.focusable.focus);
      $(element).off("focusout", ko.bindingHandlers.focusable.blur);
    });

    $(element).on("focusin", ko.bindingHandlers.focusable.focus);
    $(element).on("focusout", ko.bindingHandlers.focusable.blur);

  }
};

ko.bindingHandlers.scrollable = {
  'scroll': function() {},
  'init': function(element) {
    ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
      $(element).off("scroll", ko.bindingHandlers.scrollable.scroll);
    });

    $(element).on("scroll", ko.bindingHandlers.scrollable.scroll);

  }
};