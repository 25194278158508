tinymce.PluginManager.add('barcode', function(editor)
{
	var self = this;

	function getCodeOptions() {
		items = [];
		jQuery.each(barcodeOptions, function (i) {
			items[i] = {
				text: barcodeOptions[i].text,
				value: barcodeOptions[i].value,
				onclick: function(e) {
					editor.insertContent(barcodeOptions[i].htmlContent);
				}
			}
		});
		return items;
	};

	items = getCodeOptions();

	if (items.length) {
		editor.addButton('barcode', {
			text: 'Barcodes',
			type: 'splitbutton',
			tooltip: 'Insert Barcode',
			menu: items,
			context: 'insert'
		});
	}
});

tinymce.PluginManager.add('qrcode', function(editor)
{
	var self = this;

	function getCodeOptions() {
		items = [];
		jQuery.each(squareOptions, function (i) {
			items[i] = {
				text: squareOptions[i].text,
				value: squareOptions[i].value,
				onclick: function(e) {
					editor.insertContent(squareOptions[i].htmlContent);
				}
			}
		});
		return items;
	};

	items = getCodeOptions();

	if (items.length) {
		editor.addButton('qrcode', {
			text: 'QR',
			type: 'splitbutton',
			tooltip: 'Insert QR Code',
			menu: items,
			context: 'insert'
		});
	}
});
