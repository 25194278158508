var scriptItems = {
	ifcode: '\n{% if [TITLE]|upper == "MR" %} \n\nYou are a man \n\n{% endif %}\n',
	elsecode: '\n{% if [TITLE]|upper == "MR" %} \n\nYou are a man \n\n{% else %} \n\n You are a woman \n\n{% endif %}\n',
	elseifcode: '\n{% if [CUSTOM_CITY]|upper == "BARCELONA" %} \n\nYou live in Barcelona \n\n{% elseif [CUSTOM_CITY]|upper == "MADRID" %} \n\nYou are living in Madrid but you sould live in Barcelona \n\n{% endif %}\n',
	elseifelsecode: '\n{% if [CUSTOM_CITY]|upper == "BARCELONA" %} \n\nYou live in Barcelona \n\n{% elseif [CUSTOM_CITY]|upper == "MADRID" %} \n\nYou are living in Madrid but you sould live in Barcelona \n\n{% else %} \n\nWe do not know where you live, but we are sure it is awesome! \n\n{% endif %}\n',
	forcode: '\n{% for i in 0..10 %} \n\n * {{ i }}  \n\n{% endfor %}\n',
	numbercode: "\n{{ [CUSTOM_INTEGER_NUMBER]|number_format(2, '.', ',') }}\n",
	datecode: '\n{{ [CUSTOM_DATE]|date("m/d/Y") }}\n',
	uppercode: '\n{{ [FIRSTNAME]|upper }}\n',
	lowercode: '\n{{ [FIRSTNAME]|lower }}\n',
	currentdatecode: '\n{{ post.published_at|date("m/d/Y") }}\n',
	datemodifycode: '\n{{ post.published_at|date_modify("+1 day")|date("m/d/Y") }}\n',
};

tinymce.PluginManager.add('miniscript', function(editor)
{
	function showDialog() {
		var self = this;
		var win = editor.windowManager.open({
				title: "Miniscript code",
				minWidth: editor.getParam("code_dialog_width", 600),
				minHeight: editor.getParam("code_dialog_height", Math.min(tinymce.DOM.getViewPort().h - 200, 500)),
				spellcheck: false,
				style: 'direction: ltr; text-align: left',
				multiline: true,
				id: 'miniscript_win',
				body: [
				    {
				    	type: 'button',
				    	label: 'Documentation',
				    	text: 'Documentation',
				    	onclick: function() {
				    		url = localStorage.getItem('livedataSrv') + '/en/campaign/miniscript_documentation';
				    		window.open(url, '_blank');
				    	}
				    	
				    },
				    {
				    	type: 'label', 
				    	label: 'Select and edit the block:'
				    },
				    {
				    	type: 'listbox',
				    	name: 'miniscript_block',
				    	id: 'miniscript_block',
				    	values: [
							{ text: ' ', value: '' },
							{ text: 'IF...', value: 'if' },
							{ text: 'IF ELSE...', value: 'else' },
							{ text: 'IF ELSEIF...', value: 'elseif' },
							{ text: 'IF & ELSEIF & ELSE...', value: 'elseifelse' },
							{ text: 'FOR...', value: 'for' },
							{ text: 'NUMBER FORMAT', value: 'number' },
							{ text: 'DATE FORMAT', value: 'date' },
							{ text: 'UPPER CASE', value: 'upper' },
							{ text: 'LOWER CASE', value: 'lower' },
							{ text: 'CURRENT DATE', value: 'currentdate' },
							{ text: 'DATE MODIFY', value: 'datemodify' }
				    	], 
				    	onselect: function() {
				    		newMiniScript = '';

				    		if (typeof scriptItems[this.value() +  'code'] != 'undefined') {
					    		currentMiniScript = jQuery('#miniscript_code').val();
				    			switch(this.value()) {
				    				case 'if':
				    				case 'else':
				    				case 'elseif':
				    				case 'elseifelse':
				    				case 'for':
				    				case 'currentdate':
				    				case 'datemodify':
				    					newMiniScript = scriptItems[this.value() +  'code'];
				    					break;
				    				case 'number':
				    				case 'date':
				    				case 'upper':
				    				case 'lower':
				    					newMiniScript = currentMiniScript + scriptItems[this.value() +  'code'];
				    					break;
				    			}
				    		}

				    		jQuery('#miniscript_code').val(newMiniScript);
				    	}
				    },
				    {
				    	type: 'textbox',
				    	name: 'miniscript_code',
				    	id: 'miniscript_code',
				    	multiline: true,
				    	value: '', 
				    	rows: 15,
				    	cols: 200,
				    	minHeight: 200
				    },
				    {
				    	type: 'label',
				    	label: 'The statements are case insensitive, so you must be alert when you declare an statement in order the search is done correctly.'
				    }
				],
				buttons: [
				    {
				    	text: 'OK',
				    	onclick: function() {
				    		miniScript = jQuery('#miniscript_code').val().replace(/\n\n/g, '<br/><br/>');
				    		//miniScript = miniScript.replace(/\n/g, '<br/>');
							editor.insertContent(miniScript, {format: 'raw'});
							win.close();
				    	}
				    },
				    {
				    	text: 'Cancel',
				    	onclick: function() {
				    		if (jQuery('#miniscript_code').val() != '') {
				    			confirmMsg = 'Some of the options have been changed. Are you sure to close the dialog?';
				    			if (confirm(confirmMsg) == true) {
					    			win.close();
				    			}
				    		}else {
				    			win.close();
				    		}
				    	}
				    }
				]
		});
	}

	editor.addCommand("miniscriptEditor", showDialog);

	editor.addButton('miniscript', {
		text: '',
		tooltip: 'Insert Miniscript',
		icon: 'mce-i-minicript',
		image: '/js/plugins/miniscript/images/coding-12.png',
		onclick: showDialog
	});

	editor.addMenuItem('miniscript', {
		icon: 'code',
		text: 'Miniscript code',
		context: 'tools',
		onclick: showDialog
	});
});

