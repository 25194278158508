
tinymce.PluginManager.add('insert', function(editor) {
	var self = this;

    function getInsertOptions() {
    	items = [];
        jQuery.each(insertOptions, function (i) {
        	items[i] = {
            		text: insertOptions[i].text,
            		value:  insertOptions[i].value,
            		onclick: function (e){         			
            			editor.insertContent(insertOptions[i].htmlContent);
            		}
    		}//[source, data[i][0], data[i][0]];
        });
        return items;
    };

	items = getInsertOptions();

    if (items.length) {
        editor.addButton('insert', {
            text: "Attributes",
            type: 'splitbutton',
            tooltip: 'Insert Attribute',
            menu: items,
            context: 'insert'
        });
    }
});
